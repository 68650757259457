@tailwind base;
@tailwind components;
@tailwind utilities;

/* Zde můžete přidat vlastní styly */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --scroll: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.shadow-text {
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.parallax {
  transform: translateY(calc(var(--scroll) * -100px));
  transition: transform 0.5s ease-out;
}